import {
    mapState,
    mapMutations
} from "vuex";

export default {
    data() {
        return {
            isSearching: false,
            searchValue: this.$route.query.q || ''
        };
    },

    computed: {
        ...mapState(["userInfo"]),

        userInfo() {
            var ret = this.$store.state.userInfo;
            return Object.assign({}, ret);
        },
    },

    created() {
        if (this.$store.state.login) {
            this.$store.dispatch("updateGoodNumOfCart");
        }
    },

    methods: {
        ...mapMutations(["changeLoginAndRegisterDialog", "changeShowRegister"]),

        onSearchBlur() {
            this.isSearching = false;
        },

        onSearchFocus() {
            this.isSearching = true;
        },

        onSearch() {
            let value = this.searchValue || '';

            this.$router.push({
                path: encodeURI(`/search?q=${value}`)
            })

            // this.isSearching = false;
            this.onSearchBlur();
            this.$refs.search && this.$refs.search.blur();
        },

        clearSearch() {
            this.searchValue = '';

            this.onSearch();
        },

        login() {
            this.changeLoginAndRegisterDialog(true);
        },

        goOutLogin() {
            this.$store.dispatch("outLogin");

            if (this.$route.name !== 'musicFine') {
                this.$router.replace('/')
            }
        },
    },
}