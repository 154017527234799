<template>
  <div class="user-wrapper">
    <div class="container">
      <div class="user-main">
        <div class="user-info"  v-if="$store.state.login">
          <div v-if="$store.state.userInfo.profile_photo_url">
            <img :src="$store.state.userInfo.profile_photo_url" style="width:80px;height:80px;border-radius: 80px;border:2px solid #FF7B13;" />
          </div>
           <div v-else>
            <img src="@/assets/images/web/user_info/user_girl.png" v-if="$store.state.userInfo.sex==2" style="width:80px;height:80px;border-radius: 80px;border:2px solid #FF7B13;" />
            <img src="@/assets/images/web/user_info/user_boy.png" v-if="$store.state.userInfo.sex==1||$store.state.userInfo.sex==0" style="width:80px;height:80px;border-radius: 80px;border:2px solid #FF7B13;" />
          </div>
          <div>
            <span>{{$store.state.userInfo.name}}  </span>
          </div>
          <div>
            <img v-if="$store.state.userInfo.vip_auth!=0&&$store.state.userInfo.vip_get!=0" src="@/assets/images/web/vip-logo.svg" style="width:23px;height:17px;margin-left:-35px;"/>
          </div>
        </div>
        <div class="user-desc" v-if="vipType==2">
            <p>个人VIP权益</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 随片永久（中国）</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 不限受众数量</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 非商用授权</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 个人自媒体</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 个人影视作品</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 教育用途</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 毕业设计</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 在线生成授权书</p>
            <p>
                <a-tooltip style="cursor:pointer;" placement="bottom" title="个人VIP授权的音乐作品严禁商用，若超出授权范围使用，您可能面临平台及版权方的追责，对此产生的全部责任由您本人承担。">
                <img src="@/assets/images/web/introduce/yes_2.svg" /> 仅限个人使用 <img src="@/assets/images/web/introduce/ques_2.svg" />
                </a-tooltip>
            </p>
        </div>
         <div class="user-desc" v-if="vipType==1">
            <p>企业VIP权益</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 随片永久（全球）</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 不限受众数量</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> RF授权</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 多项目使用</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 商业广告/宣传片</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 自媒体</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 电影、纪录片</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 电视剧/综艺/动画片</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 游戏/APP/内置</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 在线教育</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 商业直播</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 广播剧/有声读物</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 线下公播</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 在线生成授权证书</p>
            <p><img src="@/assets/images/web/introduce/yes_2.svg" /> 企业发票</p>
        </div>
      </div>

      <div class="cont-main">
        <div class="sub-header">
            <span :selected="vipType==1" @click="switchVip(1)">企业VIP授权</span>
            <span>｜</span>
            <span :selected="vipType==2" @click="switchVip(2)">个人VIP授权</span>
        </div>
        <div style="text-align:center;line-height:40px;">
            温馨提示：购买VIP后，可免费下载 <router-link style="text-decoration: underline;color: red;" :to="{path:'/vipmusic',query:{id:'7'}}" target="_blank">会员专区</router-link> 音乐和 <router-link style="text-decoration: underline;color: red;" :to="{path:'/allSound',query:{id:'6'}}" target="_blank">音效库</router-link> 海量音效，无需额外付费。
        </div>
        <div class="sub-cont" v-if="vipType==1">

            <div v-for="(item) in vipData[vipType]" :key="item.vip_id" class='cont-item' @click="switchCompanyIdx(item)" :selected="companyCheckIdx==item.vip_type">
                <a-row>
                    <img src="@/assets/images/web/introduce/recommend.svg" v-if="recommend==item.vip_type"/>
                </a-row>
                <a-row>
                    <a-col>
                        <img src="@/assets/images/web/vip-logo.svg" />
                        <span>{{item.name}}</span>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <span>{{item.price}}</span>
                        <span>元/</span>
                        <span v-if="item.vip_type==1">月</span>
                        <span v-if="item.vip_type==2">季</span>
                        <span v-if="item.vip_type==3">年</span>
                        <span></span>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col> <span>RF授权</span> </a-col>
                </a-row>
                <a-row>
                    <a-col v-if="item.vip_type==1||item.vip_type==2"> <span>{{item.download_num}}首/天</span> </a-col>
                    <a-col v-if="item.vip_type==3">不限量下载</a-col>
                </a-row>
                <a-row>
                    <img src="@/assets/images/web/introduce/sel.svg" v-if="companyCheckIdx==item.vip_type"/>
                </a-row>
            </div>
        </div>
         <div class="sub-cont" v-if="vipType==2">
            <div v-for="(item) in vipData[vipType]" :key="item.vip_id" class='cont-item' @click="switchPersonalIdx(item)" :selected="personalCheckIdx==item.vip_type">
                <a-row>
                    <img src="@/assets/images/web/introduce/recommend.svg" v-if="recommend==item.vip_type"/>
                </a-row>
                <a-row>
                    <a-col>
                        <img src="@/assets/images/web/vip-logo.svg" />
                        <span>{{item.name}}</span>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <span>{{item.price}}</span>
                        <span>元/</span>
                        <span v-if="item.vip_type==1">月</span>
                        <span v-if="item.vip_type==2">季</span>
                        <span v-if="item.vip_type==3">年</span>
                        <span></span>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col> <span> 非商用授权</span> </a-col>
                </a-row>
                <a-row>
                    <a-col v-if="item.vip_type==1||item.vip_type==2"> <span>{{item.download_num}}首/天</span> </a-col>
                    <a-col v-if="item.vip_type==3"> <span>不限量下载</span> </a-col>
                </a-row>
                <a-row>
                    <img src="@/assets/images/web/introduce/sel.svg" v-if="personalCheckIdx==item.vip_type"/>
                </a-row>
            </div>
        </div>
        <div class="pay-cont">
            <div class="pay-money">
                <span>应付金额：</span>
                <span>{{needMoney}}</span>
                <span>元</span>
            </div>
            <div class="pay-item">
                <div :selected="payType==1" class="pay-item-type"  @click="switchPayType(1)">
                    <div><input type="radio" name="payType" v-model="payType" value="1"> 在线支付</div>
                    <div>
                        <img src="@/assets/images/web/introduce/alipay.svg" /><span>支付宝付款</span>
                        <img src="@/assets/images/web/introduce/wechatPay.svg" style="margin-left:2%;display:inline-block;"/><span>微信支付</span>
                    </div>
                </div>
                <div  :selected="payType==2" class="pay-item-type"  @click="switchPayType(2)">
                    <div><input type="radio" name="payType" v-model="payType" value="2"> 公对公付款</div>
                    <div><img src="@/assets/images/web/introduce/companyPay.svg"/></div>
                </div>
                <div v-if="payType==1" class="pay-item-code">
                    <div class="code-info">
                        <div class='code_wechat'>
                            <img :src="wxCode" v-if="$store.state.login"/>
                            <span v-else @click="login">点击登陆</span>
                        </div>
                        <div>
                            <img src="@/assets/images/web/introduce/wechatPay.svg" />
                            <span>  使用微信扫码付款</span>
                        </div>
                    </div>
                   <div class="code-info">
                        <div class='code_alipay'>
                            <img  :src="aliCode"  v-if="$store.state.login&&aliCode!='' "/>
                            <span  v-if="$store.state.login&&aliCode==''" @click="prePay(2)">生成支付码</span>
                            <span  v-if="!$store.state.login" @click="login">点击登陆</span>
                        </div>
                        <div>
                            <span style="background:#009fe8;padding: 1% 9%;border-radius:4px;color:#ffffff;cursor:pointer;" @click="prePay(2)">支付宝支付</span>
                        </div>
                    </div>
                </div>
                <div v-if="payType==2" class="pay-item-company">
                    <a-row><span>请按照以下信息进行对公转账付款</span></a-row>
                    <a-row><span>账户名称：</span><span>北京火星火翎文化科技有限公司</span><span @click="onCopyText('北京火星火翎文化科技有限公司')">复制</span></a-row>
                    <a-row><span>银行账户：</span><span>100010124824</span><span @click="onCopyText('100010124824')">复制</span></a-row>
                    <a-row><span>开户行：</span><span>友利银行（中国）有限公司</span><span @click="onCopyText('友利银行（中国）有限公司')">复制</span></a-row>
                    <a-row><span>付款成功后请点击按钮并向客服提交ID号和付款凭证截图，我们将在确认到账后为您开通VIP权限</span></a-row>
                    <!--<div class="ant-row" @click="comPay(3)">
                        <span>我已付款</span>
                    </div>-->
                    <div class="ant-row" style="background: #F06B03">
                        <a-popconfirm trigger="click" :icon="null" @confirm="comPay(3)">
                            <template slot="title">
                                <div class="text-center lh-1-5">
                                付款成功后请向客服提交订单号和付款凭证截图
                                <br />我们会帮助完成订单
                                </div>
                            </template>
                            <div
                                class="pay-btn"
                                size="large"
                                type="primary"
                                @click="visible = true;"
                                style="color:white"
                            >我已付款</div>
                        </a-popconfirm>
                    </div>
                </div>
                <div style="margin-top: 43px;cursor:pointer">支付即同意<router-link to="/termsOfServiceVip">《VIP服务协议》</router-link></div>
            </div>
            
        </div>
        <div class="pay-footer">
            <span>温馨提示： 网站设置防盗刷系统，请合理使用下载权限。如用户短时间内高频或大量下载可能触发该系统，将暂时暂停账号下载功能，如有疑问 </span>
            <span style="border: 1px solid #E5E5E6; border-radius: 14px;padding:6px 18px;cursor:pointer;color: #333333;" @click="Chatra">
                <img src="@/assets/images/web/introduce/service.png" /> 
                点我联系
            </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 import {tooltip as ATooltip, Popconfirm as APopconfirm} from 'ant-design-vue'
  import HeaderMixins from '@/components/common/header_mixins'
  import {copyStr} from '@/utils/function'

  export default {
    data() {
      return {
          vipType: 1,
          recommend: 3,
          companyCheckIdx: 3,
          personalCheckIdx: 3,
          payType: 1,
          vipData: {},
          needMoney: '',
          wxCode: "",
          aliCode: "",
          timer: null,
          wx_order_id: "",
          ali_order_id: "",
      }
    },
    mixins: [HeaderMixins],
    created() {
        this.vipType = this.$route.query.vipType|| 1;
        this.getVipData();
    },
    watch: {
      $route() {
        this.vipType = this.$route.query.vipType|| 1;
      }
    },
    computed: {
    },

    components: {
        ATooltip,
        APopconfirm,
    },
    beforeDestroy() {
        clearInterval(this.timer);        
        this.timer = null;
    },
    methods: {
        getVipData(){
            this.isLogin();
            let that = this;
            this.$axios.VIPLIST().then(res => {
                if (res.data.code == 0) {
                    this.vipData = res.data.data.list;
                    this.needMoney = this.vipData[that.vipType][that.companyCheckIdx - 1].price;
                    this.prePay();
                }
            })
        },
        switchVip(vipIdx){
            this.isLogin();
            this.vipType = vipIdx;
            if(this.vipType == 1){
                this.needMoney = this.vipData[this.vipType][this.companyCheckIdx - 1].price;
            } else {
                this.needMoney = this.vipData[this.vipType][this.personalCheckIdx - 1].price;
            }  
            this.prePay();
        },
        Chatra(){
            window.Chatra('openChat', true)
        },
        switchPayType(payTypeIdx){
            this.payType = payTypeIdx;
        },
        switchCompanyIdx(item){
            this.isLogin();
            this.companyCheckIdx = item.vip_type;
            this.needMoney = item.price;
            this.prePay();
        },
        switchPersonalIdx(item){
            this.isLogin();
            this.personalCheckIdx = item.vip_type;
            this.needMoney = item.price;
            this.prePay();
        },
        onCopyText(str){
            try {
                copyStr(str);
                this.$message.success('复制成功', 1);
            } catch (error) {
                console.error(error);
            }
        },
        isLogin(){
            if(!this.$store.state.login) {
                this.changeLoginAndRegisterDialog(true);
            }
        },
        comPay(pay_way = 3) {
            let vip_id = "";
            if(this.vipType == 1){
                vip_id = this.vipData[this.vipType][this.companyCheckIdx - 1].vip_id;
            } else {
                vip_id = this.vipData[this.vipType][this.personalCheckIdx - 1].vip_id;
            }
            //生成订单
            let params = {
                'vip_id': vip_id,
                'pay_way': pay_way,
            };
            this.$axios.viporgPay(params).then(res => {
                if (res.data.code == 0) {
                    //let data = res.data.data;
                    //let order_id = data.order_id;
                    let url = `/member?menukey=4`;
                    this.$router.replace(url);
                }
            })
        },
        prePay(pay_way = 1){
            window.clearInterval(this.timer);
            let vip_id = "";
            if(this.vipType == 1){
                vip_id = this.vipData[this.vipType][this.companyCheckIdx - 1].vip_id;
            } else {
                vip_id = this.vipData[this.vipType][this.personalCheckIdx - 1].vip_id;
            }
            //生成订单
            let params = {
                'vip_id': vip_id,
                'pay_way': pay_way,
            };
            let that = this;
            this.$axios.qrCode(params).then(res => {
                if (res.data.code == 0) {
                    let data = res.data.data;
                    if(pay_way == 1) {
                        that.wxCode = data.pay_data;
                        that.wx_order_id = "";
                        that.wx_order_id = data.order_id
                    }
                    if(pay_way == 2) {
                        that.aliCode = data.pay_data;
                        that.ali_order_id = "";
                        that.ali_order_id = data.order_id;
                    }
                    let oid = that.wx_order_id;
                    if(that.ali_order_id != "") {
                        oid = oid + ',' + that.ali_order_id;
                    }
                    that.timer = setInterval(() => {
                        that.checkPayStatus({'order_id':oid});
                    }, 4000);
                }
            })
        },
        checkPayStatus(params){
            this.$axios.PayCheckOrder(params).then(res => {
                if (res.data.code == 0) {
                    let pay_status = res.data.data ? res.data.data.pay_status : '';
                    if (pay_status == '1') {
                        clearInterval(this.timer);
                        this.$message.success("您的订单已支付成功。");
                        this.$router.replace('/allmusic');
                    }
                }
            })
        },
    }
  }
</script>
<style>
    .app-outer{
        background: #F9FAFA;
    }
</style>
<style lang="scss" scoped>
  .user-wrapper {
    width: 100%;
    padding-top: 24px;
    background: #F9FAFA;
    vertical-align:top;
    .user-main{
      display:inline-block;
      width: 20%;
      height: 912px;
      background: #FFFFFF;
      .user-info{
        height: 160px;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        div:nth-child(1){
          width: 100%;
          height:109px;
          padding-top: 29px;
          text-align: center;
        }
        div:nth-child(3){
          width: 40%;
          margin: 0 auto;
          margin-top: -40px;
          text-align:right;
          margin-right: 38%;
        }
      }
      .user-desc{
        width: 90%;
        text-align: left;
        margin-left: 30%;
        p{
            margin-top: 20px;
        }
        p:nth-child(1){
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 25px;
            width: 101px;
        }
        p:not(nth-child(1)){
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
      }
    }
    .cont-main {
      display:inline-block;
      vertical-align:top;
      width: 73.9%;
      margin-left: 1%;
      background: #FFFFFF;
      height: 912px;
      .sub-header{
        width:100%;
        line-height: 60px;
        border-bottom: 1px solid #F0F0F6;
        span{
            display:inline-block;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
        }
        span[selected] {
            color: #FF7B13;
        }
        span:nth-child(2){
            width:6.4%;
            text-align:center;
        }
        span:nth-child(1){   
            width: 46.8%;
            text-align:right;
            cursor: pointer;
        }
        span:nth-child(3) {
            width: 46.8%;
            text-align:left;
            cursor: pointer;
        }
        span:nth-child(1):hover, span:nth-child(3):hover{
            color: #FF7B13;
        }
      }
      .sub-cont{
          height: 294px;
          padding-top: 63px;
          text-align:center;
          .cont-item:not(:nth-child(1)){
            margin-left: 2%;
          }
          .cont-item:hover{
            cursor: pointer;
            border: 1px solid RGBA(244, 66, 24, 1);
          }
          .cont-item[selected]{
            border: 1px solid RGBA(244, 66, 24, 1);
          }
          .cont-item {
            display: inline-block;
            width: 23.5%;
            height: 200px;
            border-radius: 2px;
            border: 1px solid #EEEEEE;
            text-align:center;
            .ant-row:nth-child(1){
                text-align:left;
                margin-top: -15px;
                margin-left: -15px;
                line-height: 74px;
                height: 74px;
            }
            .ant-row:nth-child(2){
                margin-top: -30px;
                font-size: 18px;
                font-weight: 500;
                color: #F44218;
                line-height: 25px;
                height: 25px;
                span{
                    display:inline-block;
                    margin-left: 1%;
                }
            }
            .ant-row:nth-child(3){
                margin-top: 21px;
                span:nth-child(1){
                    font-size: 34px;
                    font-weight: 500;
                    color: #F44218;
                    line-height: 48px;
                }
                span:nth-child(2), span:nth-child(3){
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }
                span:nth-child(3){
                    color: rgba(244, 46, 37, 1);
                }
                span:nth-child(4){
                    display: block;
                    width: 90%;
                    margin: 0 auto;
                    margin-top: 13px;
                    border-bottom: 1px solid #EAEAEA;
                }
              }
            }
            .ant-row:nth-child(4){
                margin-top: 13px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                height: 20px;
            }
            .ant-row:nth-child(5){
                margin-top: 4px;
                font-size: 12px;
                font-weight: 400;
                color: #999999;
                line-height: 17px;
                height: 17px;
            }
            .ant-row:nth-child(6){
                margin-top: -21px;
                text-align:right;
                line-height: 17px;
                height: 17px;
            }
        }
        .sub-cont,  .pay-cont, .pay-footer{
            width: 94.6%;
            margin: 0 auto;
        }
        .pay-cont{
            height: 494px;
            border-radius: 2px;
            border: 1px solid rgba(4, 4, 5, 0.1);
            .pay-money, .pay-item{
                width: 86%;
                margin: 0 auto;
            }
            .pay-money{
                line-height: 48px;
                margin-top: 33px;
                margin-bottom: 38px;
                text-align: center;
                span{
                    height: 48px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }
                span:nth-child(2){
                    font-size: 34px;
                    font-weight: 500;
                    color: rgba(244, 66, 24, 1);
                }
            }
            .pay-item {
                .pay-item-type{
                    width: 45%;
                    line-height: 54px;
                    display: inline-block;
                    border-radius: 2px;
                    border: 1px solid #E5E5E5;
                    margin-bottom: 43px;
                    cursor:pointer;
                    &[selected]{
                        border: 1px solid #4987D7;
                    }
                    div{
                        display: inline-block;
                        padding: 0 3%;
                    }
                    div:nth-child(1){
                        width: 35%;
                    }
                    div:nth-child(2){
                        width: 65%;
                        text-align: right;
                    }
                }
                .pay-item-type:hover{
                    border: 1px solid #4987D7;
                }
                .pay-item-type:nth-child(1){
                    margin-right: 5%;
                }
                .pay-item-type:nth-child(2){
                    margin-left: 5%;
                }
                .pay-item-code{
                    .code-info {
                        display: inline-block;
                        width: 50%;
                        text-align:center;
                    }
                    .code_wechat, .code_alipay{
                        display: inline-block;
                        padding: 9px;
                        height: 164px;
                        width: 164px;
                        border-radius: 2px;
                        border: 1px solid #53A928;
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        cursor:pointer;
                        img, span{
                            width:146px;
                            line-height:146px;
                            display:inline-block;
                        }
                        span{
                            background:#ECECEC;
                        }
                    }
                    .code_alipay{
                        border: 1px solid #01A2E9;
                    }
                    .code-info div{
                        margin-top: 12px;
                    }
                }
                .pay-item-code, .pay-item-company{
                    height: 200px;
                }
                .pay-item-company{
                    .ant-row{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                    }
                    .ant-row:nth-child(1){
                        margin-bottom: 22px;
                        color: rgba(0, 0, 0, 0.85);
                    }
                    .ant-row:nth-child(2), .ant-row:nth-child(3), .ant-row:nth-child(4){
                        margin-top: 5px;
                        font-size: 14px;
                        span:nth-child(1){
                            color: #262626;
                        }
                        span:nth-child(2){
                            color: rgba(0, 0, 0, 0.85);
                        }
                        span:nth-child(3){
                            font-size: 12px;
                            color: #666666;
                            margin-left: 1%;
                            cursor: pointer;
                        }
                    }
                    .ant-row:nth-child(5){
                        margin-top: 27px;
                        color: rgba(0, 0, 0, 0.85);
                    }
                    .ant-row:nth-child(6){
                        width: 100px;
                        margin: 0 auto;
                        margin-top: 27px;
                        line-height: 40px;
                        background: #FF872A;
                        border-radius: 7px;
                        text-align:center;
                        cursor:pointer;
                        span{
                            font-size: 16px;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
        .pay-footer{
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
            margin-top:25px;
            text-align:left;
        }
    }
  }
</style>